<template>
  <div>
    <b-alert v-model="editSuccess" variant="success" dismissible>
      {{ this.message }}
    </b-alert>
    <b-alert v-model="errorEdit" variant="danger" dismissible>
      {{ this.errorMsg }}
    </b-alert>
    <h1 class="text-left">{{ $t('nav.myAccount') }} {{user.email}}</h1>
    <div>
      <b-row align-h="center">
        <b-col>
          <div style="max-width: 400px; margin-left: auto; margin-right: auto">
            <h3 class="text-center mb-5" style="background-color: #0000FF; color:white; padding: 5px; border-style: solid;
            border-width: medium; border-color: black"> {{ $t('register.billingAddress') }}</h3>
            <b-row align-h="center" class="justify-content-center text-center">
              <b-col class="text-left" sm="12">
                <label :for="firstName">{{ $t('register.firstName') }}</label>
              </b-col>
              <b-col sm="12">
                <b-form-input ref="lastName" style="max-width: 400px" id="firstName" v-model="lastName"
                              :disabled="disabled"
                              type="text"></b-form-input>
              </b-col>
            </b-row>
            <b-row class="justify-content-center">
              <b-col class="text-left" sm="12">
                <label :for="lastName">{{ $t('register.lastName') }}</label>
              </b-col>
              <b-col sm="12">
                <b-form-input ref="firstName" id="lastName" :disabled="disabled" type="text"
                              v-model="firstName"></b-form-input>
              </b-col>
            </b-row>
            <b-row class="justify-content-center">
              <b-col class="text-left" sm="12">
                <label :for="street">{{ $t('register.street') }}</label>
              </b-col>
              <b-col sm="12">
                <b-form-input ref="street" id="street" v-model="user.street" :disabled="disabled"
                              type="text"></b-form-input>
              </b-col>
            </b-row>
            <b-row class="justify-content-center">
              <b-col class="text-left" sm="12">
                <label :for="Postcode">{{ $t('register.postcode') }},</label>
                <label :for="City">{{ $t('register.city') }}:</label>
              </b-col>
              <b-col sm="4">
                <b-form-input ref="postCode" id="Postcode" v-model="user.zip" :disabled="disabled"
                              type="text"></b-form-input>
              </b-col>
              <b-col sm="8">
                <b-form-input ref="city" id="City" v-model="user.city" :disabled="disabled" type="text"></b-form-input>
              </b-col>
            </b-row>
            <b-row class="justify-content-center">
              <b-col class="text-left" sm="12">
                <label :for="Country">{{ $t('register.country') }}</label>
              </b-col>
              <b-col sm="12">
                <b-form-select ref="country" :disabled="disabled" id="Country" v-model="user.country"
                               :options="countries"></b-form-select>
              </b-col>
            </b-row>
            <b-row class="justify-content-center mt-2">
              <b-col class="text-left" sm="12">
                <label :for="Email">E-Mail</label>
              </b-col>
              <b-col class="text-left" sm="12">
                <p>
                  {{ user.email }}
                </p>
              </b-col>
            </b-row>
            <b-row class="my-5">
              <b-col sm="12" class="text-center">
                <b-button ref="btnEditSave" @click="edit" class="btn sansSerif ml-auto" style="background-color: #0000FF; border-radius: 0!important;
         color: white; max-width: 300px; width: 100%">
                  {{ this.buttonText }}
                </b-button>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <b-col>
          <div style="max-width: 400px; margin-left: auto; margin-right: auto">
            <h3 class="text-center mb-5" style=" border-style: solid;
            border-width: medium; border-color: black; background-color: #0000FF; color:white; padding: 5px;">
              {{ $t('changePassword') }}
            </h3>
            <b-row align-h="center" style="margin-bottom: 5px">
              <b-col class="text-left" sm="12">
                <label :for="'currentPassword'">{{ $t('currentPassword') }}:</label>
              </b-col>
              <b-col sm="12">
                <b-form-input :state="currentPasswordState"
                              required
                              :id="'currentPassword'"
                              :type="'password'"
                              v-model="currentPassword"
                              aria-describedby="currentPassword-feedback">
                </b-form-input>
                <b-form-invalid-feedback id="currentPassword-feedback">
                  {{ $t('enterCurrentPas') }}
                </b-form-invalid-feedback>
              </b-col>
            </b-row>
            <b-row align-h="center" style="margin-bottom: 5px">
              <b-col class="text-left" sm="12">
                <label :for="'password'">Password:</label>
              </b-col>
              <b-col sm="12">
                <b-form-input :state="passwordState"
                              required
                              :id="'password'"
                              :type="'password'"
                              v-model="password"
                              aria-describedby="password-feedback">
                </b-form-input>
                <b-form-invalid-feedback id="password-feedback">
                  {{ $t('pleaseEnterNewPas') }}
                </b-form-invalid-feedback>
              </b-col>
            </b-row>
            <b-row align-h="center" style="margin-bottom: 5px">
              <b-col class="text-left" sm="12">
                <label :for="'passwordCheck'">{{ $t('register.confirmPassword') }}:</label>
              </b-col>
              <b-col sm="12">
                <b-form-input :state="passwordCheckState"
                              required
                              :id="'passwordCheck'"
                              :type="'password'"
                              v-model="passwordCheck"
                              aria-describedby="passwordCheck-feedback">
                </b-form-input>
                <b-form-invalid-feedback id="passwordCheck-feedback">
                  {{ $t('register.passwordDontMatch') }}
                </b-form-invalid-feedback>
              </b-col>
            </b-row>
            <b-row align-h="center" style="margin-bottom: 5px">
              <b-col sm="12" class="text-center">
                <b-button @click="changePassword" class="btn sansSerif ml-auto" style="background-color: #0000FF; border-radius: 0!important;
         color: white; max-width: 300px; width: 100%">
                  {{ $t('changePassword') }}
                </b-button>
              </b-col>
            </b-row>
            <b-row align-h="center" style="margin-bottom: 5px; margin-top: 20px">
              <b-col sm="12" class="text-center">
                <b-button v-b-modal.modal-delete class="btn sansSerif ml-auto btn-danger" style="border-radius: 0!important;
         color: white; max-width: 300px; width: 100%">
                  {{ $t('deleteAccount') }}
                </b-button>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-modal @ok="deleteAccount" id="modal-delete" title="Delete account">
      <p class="my-4">{{ $t('deleteQuestion') }}</p>
    </b-modal>
  </div>
</template>

<script>
import {mapState} from "vuex";
import UserDataService from "../services/UserDataService";

const countries = require('i18n-iso-countries')
countries.registerLocale(require('i18n-iso-countries/langs/en.json'))
export default {
  name: "Overview",
  data() {
    return {
      password: "",
      passwordCheck: '',
      submitted: false,
      country: "IT",
      mode: 'read',
      editSuccess: false,
      currentPassword: '',
      message: '',
      errorMsg: '',
      errorEdit: false,
      firstName: '',
      lastName: '',
    }
  },
  computed: {
    disabled() {
      if (this.mode === "read") {
        return "disabled";
      } else {
        return false;
      }
    },
    buttonText() {
      if (this.mode === "read") {
        return this.$i18n.messages[this.$i18n.locale].edit;
      } else {
        return this.$i18n.messages[this.$i18n.locale].save;
      }
    },
    currentPasswordState() {
      return this.currentPassword.length <= 0 && this.submitted ? false : null
    },
    passwordState() {
      return this.password.length <= 0 && this.submitted ? false : null
    },
    passwordCheckState() {
      return this.password === this.passwordCheck ? null : false
    },
    countries() {
      const list = countries.getNames('en', {select: 'official'})
      console.log(list);
      return Object.keys(list).map((key) => ({value: key, text: list[key]}))
    },
    state() {
      if (this.submitted) {
        return false;
      } else {
        return null;
      }
    },
    ...mapState(["user"]),
  },
  methods: {
    edit() {
      if (this.mode === 'read') {
        console.log("READ");
        this.mode = "write";
      } else if (this.mode === "write") {
        console.log("WRITE!");
        this.mode = "read";
        let name = this.firstName + " " + this.lastName;
        let data = {
          city: this.user.city,
          zip: this.user.zip,
          street: this.user.street,
          name: name,
          country: this.user.country
        }
        UserDataService.editUser(data, {'headers': {'x-access-token': this.$store.getters.accessToken}}).then(response => {
          console.log(response);
          console.log(response.data.data);
          this.$store.commit("SET_USER", {user: response.data.data});
          this.editSuccess = true;
          localStorage.user = JSON.stringify(response.data.data);
          this.editSuccess = true;
          this.message = this.$i18n.messages[this.$i18n.locale].changesSaveSuc
        }).catch(error => {
          console.log(error);
          this.errorMsg = error.response.data.message;
          this.errorEdit = true;
        });
      }
    },
    changePassword() {
      this.submitted = true;
      console.log("click");
      console.log()
      if ((this.currentPasswordState || this.currentPasswordState === null) &&
          (this.passwordState || this.passwordState === null) && (this.passwordCheckState || this.passwordCheckState === null)) {
        let data = {
          currentPassword: this.currentPassword,
          password: this.password,
        };
        let header = {
          'headers': {'x-access-token': this.$store.getters.accessToken}
        };
        UserDataService.changePassword(data, header).then(response => {
          console.log(response);
          this.editSuccess = true;
          this.message = this.$i18n.messages[this.$i18n.locale].passwordChangeSuccess;
          this.password = '';
          this.currentPassword = '';
          this.passwordCheck = '';
          this.submitted = false;
        }).catch(error => {
          console.log(error);
          this.errorMsg = error.response.data.message;
          this.errorEdit = true;
        })
      }
    },
    deleteAccount(){
      let header = {
        'headers': {'x-access-token': this.$store.getters.accessToken}
      };
      UserDataService.deleteAccount(header).then(response => {
        console.log(response)
        this.$store.commit('SET_ROLE', {role: null});
        this.$store.commit('SET_ACCESS_TOKEN', {accessToken: null});
        this.$store.commit('SET_USER', {
          user: {
            id: 1,
            name: "Guest",
            zip: "",
            street: "",
            country: "",
            city: "",
            newsletter: 0,
            email: "",
          }
        });
        localStorage.removeItem('role');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('user');
        this.submitted = false;
        let params = {deleteMsg: this.$i18n.messages[this.$i18n.locale].deleteSuccess,}
        this.$router.push({name: 'home', params});
      }).catch(error => {
        console.log(error);
      });
    }
  },
  mounted() {
    if (this.user.name !== "") {
      let nameArray = this.user.name.split(" ");
      this.firstName = nameArray[0];
      this.lastName = nameArray[1];
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Ubuntu&display=swap');

.ubuntu {
  font-family: 'Ubuntu', sans-serif !important;
}

.sansSerif {
  font-family: 'Open Sans', sans-serif !important;
}

.breadcrumb-item a {
  color: #0000FF;
}
</style>
